<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new email contact</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new email contact</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onSubmitForm"> <!-- @keyup.enter.native="create" -->
                            <!-- <template v-if="website">
                                <p>This form will be linked to the website
                                    <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: website.id } }">{{ website.label }}</router-link>.
                                    You can update the website content from this form.</p>
                            </template> -->
                            <!-- <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <p v-bind="attrs" v-on="on">What is a form alias? <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text text-lighten-2"/></p>
                                </template>
                                <p>When you create a form, we generate a unique identifier for it. When a form is attached to a server, it is generally accessible at <span class="text-no-wrap">/mnt/form/&lt;id&gt;</span>. When you access the storage form via file server, we create a convenient link from your home directory to the form mount point at <span class="text-no-wrap">~/&lt;account-alias&gt;/&lt;form-alias&gt;</span>. This makes it easier to recognize and access forms that you have access to. A form alias must be unique within the account.</p>
                            </v-tooltip>
                            <v-text-field
                                ref="formAliasInput"
                                v-model="newFormAlias"
                                label="Form alias"
                                :rules="newFormAliasRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                hint="The directory name to use for the form (you can change this later)"
                                type="text"
                                outlined
                            >
                            </v-text-field> -->
                            <p>
                                An email contact is a person who has interacted with your business in some way.
                                An email contact may subscribe or unsubscribe from communications.
                            </p>
                            <!-- TODO: should we ask user for email and domain input separately? then we can show list of domains they have; or if we ask for entire email address, we need to parse it to highlight if the domain they entered is in their account or not... -->
                            <!-- <v-row no-gutters align="center"> -->
                                <!-- <v-text-field
                                    ref="emailContactAliasInput"
                                    v-model="newEmailContactAlias"
                                    label="Alias"
                                    :rules="newEmailContactAliasRules"
                                    validate-on-blur
                                    color="teal darken-2"
                                    type="text"
                                    outlined
                                    dense
                                    hint="Optional. Full name, first name, or nickname."
                                >
                                </v-text-field> -->
                                <v-text-field
                                    ref="emailContactEmailInput"
                                    v-model="newEmailContactEmail"
                                    label="Email"
                                    :rules="newEmailContactEmailRules"
                                    validate-on-blur
                                    color="teal darken-2"
                                    required
                                    type="text"
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            <!-- </v-row> -->
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.row > .v-input:first-of-type {
    margin-right: 4px;
}
.row > .v-input + .v-input {
    margin-left: 4px;
    margin-right: 4px;
}
.row > .v-input:last-of-type {
    margin-left: 4px;
}
</style>

<script>
import { mapState } from 'vuex';
import { isValidEmail /* , compact */ } from '@/sdk/input';

export default {
    data: () => ({
        domainList: [],

        error: null,
        website: null,
        // create form
        createEmailContactForm: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newEmailContactEmail: null,
        newEmailContactEmailRules: [
            (v) => !!v || 'Email is required',
            (v) => isValidEmail(v) || 'Must use a valid email address',
        ],
        // newEmailContactAlias: null,
        // newEmailContactAliasRules: [
        //     // (v) => !!v || 'Alias is required', // actually it's optional;  but maybe we limit the allowed characters?
        // ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newEmailContactEmail === 'string' && this.newEmailContactEmail.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        onSubmitForm() {
            if (!this.isFormComplete) {
                return;
            }
            this.createEmailContact();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createEmailContact() {
            try {
                this.error = false;
                this.$store.commit('loading', { createEmailContact: true });
                console.log('createEmailContact');
                const request = {
                    email: this.newEmailContactEmail,
                    // alias: this.newEmailContactAlias,
                };
                // if (this.website) {
                //     request.website_id = this.website.id;
                // }
                const response = await this.$client.account(this.$route.params.accountId).emailContact.create(request);
                console.log('createEmailContact response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-email-contact', params: { accountId: this.$route.params.accountId, emailContactId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email contact' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email contact' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create email contact', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email contact' });
            } finally {
                this.$store.commit('loading', { createEmailContact: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
